import { useAppContext } from "utils/react-hooks/useAppContext";
import React, { FC, useMemo } from "react";
import { QuestionnaireLeadTypes } from "types/questionnaire.type";
import { TQuestionnaireConfigData } from "types/app-context.type";
import { LeadTypes } from "@leadpro/forms";

import { QuestionnaireDynamicForm } from "./QuestionnaireDynamicForm";
import { Center } from "@chakra-ui/react";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { TurnstileGuard } from "components/TurnstileGuard/TurnstileGuard";

const LeadTypeToQuestionnaireSchemaMap: Record<
  QuestionnaireLeadTypes,
  keyof Pick<
    TQuestionnaireConfigData,
    | "landlordSchema"
    | "letSchema"
    | "mortgageRequestSchema"
    | "vendorSchema"
    | "saleSchema"
  >
> = {
  [LeadTypes.Landlord]: "landlordSchema",
  [LeadTypes.Let]: "letSchema",
  [LeadTypes.MortgageRequest]: "mortgageRequestSchema",
  [LeadTypes.Sale]: "saleSchema",
  [LeadTypes.Vendor]: "vendorSchema"
};

interface IProps {
  onSubmit: () => void;
}

export const QuestionnaireForm: FC<IProps> = ({ onSubmit }) => {
  const {
    config: { pageQuestionnaireConfig },
    questionnaireStore: { originLead }
  } = useAppContext();

  const questionnaireFormSchema = useMemo(() => {
    if (!originLead?.type) return null;
    const schemaKey = LeadTypeToQuestionnaireSchemaMap[originLead.type];

    return pageQuestionnaireConfig[schemaKey] || null;
  }, [originLead?.type, pageQuestionnaireConfig]);

  if (!questionnaireFormSchema)
    return (
      <Center
        width={"100%"}
        height={"100%"}
        position={"absolute"}
        top={0}
        left={0}
      >
        <ErrorMessage>
          Lead type not supported by this questionnare.
        </ErrorMessage>
      </Center>
    );

  return (
    <TurnstileGuard formType="form">
      {getToken => (
        <QuestionnaireDynamicForm
          formSchema={questionnaireFormSchema}
          getToken={getToken}
          onSubmit={onSubmit}
        />
      )}
    </TurnstileGuard>
  );
};
