import { Box, VStack } from "@chakra-ui/react";
import React, { FC, useCallback, useMemo } from "react";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { Form, Formik } from "formik";
import { FormControl } from "components/FormControl/FormControl";
import {
  getFormControlPropsFromSchemaItem,
  getInitialFormFieldValuesFromSchema,
  getTruncatedValues,
  TFormFromSchemaValues,
  validateFormFieldValuesFromSchema
} from "utils/form-schema.utils";
import { NumberedInput } from "./NumberedInput";
import { FormikConfig } from "formik/dist/types";
import { createFormResponse } from "api/forms-response.api";

import { GDPRForm } from "routes/components/GDPRForm";
import { GDPRProtectedBrandedButton } from "../../../routes/components/GDPRProtectedBrandedButton";
import { FormEventsEnum } from "enums/form-events.enum";
import { PrivacyPolicy } from "../../PropertyValuationWidget/components/PrivacyPolicy";
import { TurnstileGuard } from "components/TurnstileGuard/TurnstileGuard";

interface IProps {
  onSubmit: () => void;
}

export const FormFromSchema: FC<IProps> = ({ onSubmit }) => {
  const {
    config: {
      pageFormConfig,
      host,
      appId,
      gdprCheckboxEnabled,
      privacyPolicyLink
    }
  } = useAppContext();
  const schema = pageFormConfig.schema;

  const formFieldElements = useMemo(() => {
    return (
      <>
        {schema.order.map((fieldKey, index) => {
          return (
            <NumberedInput key={fieldKey} orderNumber={index + 1}>
              <FormControl
                {...getFormControlPropsFromSchemaItem(schema, fieldKey)}
              />
            </NumberedInput>
          );
        })}
      </>
    );
  }, [schema]);

  const initialValues: TFormFromSchemaValues = useMemo(
    () => getInitialFormFieldValuesFromSchema(schema, gdprCheckboxEnabled),
    [schema, gdprCheckboxEnabled]
  );

  const validate: FormikConfig<
    TFormFromSchemaValues
  >["validate"] = useCallback(
    ({ gdprCheckbox, ...rest }: TFormFromSchemaValues) =>
      validateFormFieldValuesFromSchema(rest, schema),
    [schema]
  );

  const handleSubmit = useCallback(
    getToken => async ({ gdprCheckbox, ...rest }: TFormFromSchemaValues) => {
      const token = await getToken();
      await createFormResponse(
        appId,
        {
          data: getTruncatedValues(rest, schema),
          meta: {
            host,
            gdprCheckbox
          }
        },
        token
      );

      window.parent.postMessage(
        {
          type: FormEventsEnum.FORM_SUBMITTED
        },
        "*"
      );

      onSubmit();
    },
    [onSubmit, host, appId, schema]
  );

  return (
    <TurnstileGuard formType="form">
      {getToken => (
        <Formik<TFormFromSchemaValues>
          initialValues={initialValues}
          enableReinitialize={true}
          validate={validate}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={handleSubmit(getToken)}
        >
          {({ isSubmitting, values: formValues }) => (
            <Form style={{ width: "100%", maxWidth: "680px" }}>
              <VStack spacing={10} alignItems={"flex-start"} width={"100%"}>
                {formFieldElements}
                <VStack
                  spacing={14}
                  marginTop={"44px"}
                  paddingLeft={"64px"}
                  alignItems={"flex-start"}
                  width={"100%"}
                >
                  <Box>
                    <GDPRForm />
                  </Box>
                  <Box width={"100%"} textAlign={"center"}>
                    <GDPRProtectedBrandedButton
                      type={"submit"}
                      colorScheme={"primary"}
                      isFullWidth={true}
                      maxW={"554px"}
                      rounded={"full"}
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                      gdprCheckbox={formValues.gdprCheckbox}
                    >
                      Submit response
                    </GDPRProtectedBrandedButton>
                    {!!privacyPolicyLink && (
                      <PrivacyPolicy
                        link={privacyPolicyLink}
                        text={
                          "By submitting a response, you agree to the terms set out by our"
                        }
                      />
                    )}
                  </Box>
                </VStack>
              </VStack>
            </Form>
          )}
        </Formik>
      )}
    </TurnstileGuard>
  );
};
