import React, { FC, useCallback, useState } from "react";
import Turnstile from "react-turnstile";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Box } from "@chakra-ui/react";

const env = runtimeEnv();

interface IProps {
  children: (getToken: () => string | undefined) => React.ReactNode;
  formType: "ivt" | "form";
}

export const TurnstileGuard: FC<IProps> = ({ children, formType }) => {
  const [token, setToken] = useState<string | undefined>(undefined);

  if (typeof children !== "function")
    throw new Error("Child needs to be a function!");

  const getToken = useCallback(() => {
    return token;
  }, [token]);

  return (
    <Box flex={1}>
      {children(getToken)}
      <Box
        mt={4}
        maxWidth={formType === "form" ? 680 : "auto"}
        pl={formType === "form" ? "64px" : 0}
      >
        <Turnstile
          sitekey={env.REACT_APP_RECAPTCHA_SITEKEY}
          onVerify={token => {
            setToken(token);
          }}
          onError={error => {
            console.error(error);
            throw new Error("Error in TurnstileGuard", error);
          }}
          theme={"light"}
          size={"flexible"}
          fixedSize
        />
      </Box>
    </Box>
  );
};
