import React, { useCallback, useState } from "react";
import generateAddress from "utils/formatAddress";
import {
  ContactDetailsForm,
  Layout,
  PropertyDetailsForm,
  ValuationReport
} from "./components";
import { createIncompleteLead, createLead } from "api/ivt-lead.api";
import { IVTEventsEnum } from "enums/ivt-events.enum";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { PropertyValuationFormDataType } from "types/property-valuation-form-data.type";
import { FormWizard } from "components/FormWizard/FormWizard";
import { FormikHelpers } from "formik/dist/types";
import { Wizard } from "components/Wizard/Wizard";
import { LeadTypeToReportModeMap } from "../../constants/lead-type-to-report-mode-map";
import { PropertyTypeEnum } from "../../enums/property-type.enum";

export const PropertyValuationWidget = () => {
  const {
    config: {
      appId,
      host,
      facebookPixelId,
      utmParams,
      pageIvtConfig: {
        incompleteValuationLeadEmailsEnabled,
        reportDownloadEnabled
      }
    },
    ivtStore: { lead, setLead, setComparables, setValuationReportMode }
  } = useAppContext();
  const [incompleteLeadUUID, setIncompleteLeadUUID] = useState<string | null>(
    null
  );

  const handleSubmitIncompleteLead = useCallback(
    async (values: Partial<PropertyValuationFormDataType>) => {
      if (incompleteValuationLeadEmailsEnabled) {
        const {
          type,
          postcode,
          bedrooms,
          addressLine1,
          addressLine2,
          addressLine3
        } = values;
        const { publicUuid } = await createIncompleteLead(appId, {
          type,
          postcode,
          bedrooms,
          address: generateAddress({
            addressLine1,
            addressLine2,
            addressLine3,
            postcode
          })
        });
        setIncompleteLeadUUID(publicUuid);
      }

      window.parent.postMessage(
        {
          type: IVTEventsEnum.IVT_PARTIAL_LEAD
        },
        "*"
      );
    },
    [appId, incompleteValuationLeadEmailsEnabled, setIncompleteLeadUUID]
  );

  const handleFormSubmit = useCallback(
    next => async (
      values: Partial<PropertyValuationFormDataType>,
      actions: FormikHelpers<Partial<PropertyValuationFormDataType>>
    ) => {
      const {
        postcode,
        addressLine1,
        addressLine2,
        addressLine3,
        bedrooms,
        eta,
        bathrooms,
        receptions,
        type,
        email,
        firstName,
        lastName,
        phone,
        gdprCheckbox,
        token,
        propertyType
      } = values;

      try {
        const [lead] = await createLead(
          appId,
          {
            publicUuid: incompleteLeadUUID,
            postcode: postcode?.toUpperCase(),
            address: generateAddress({
              addressLine1,
              addressLine2,
              addressLine3,
              postcode
            }),
            type,
            bedrooms,
            email,
            host,
            firstName,
            lastName,
            phone,
            gdprCheckbox,
            eta,
            bathrooms,
            receptions,
            facebookPixelId,
            utmSource: utmParams.utm_source,
            utmMedium: utmParams.utm_medium,
            utmCampaign: utmParams.utm_campaign,
            utmContent: utmParams.utm_content,
            utmTerm: utmParams.utm_term,
            utmGclid: utmParams.utm_gclid,
            propertyType:
              propertyType === PropertyTypeEnum.OTHER ? undefined : propertyType
          },
          token
        );

        setValuationReportMode(LeadTypeToReportModeMap[lead.type]);
        setLead(lead);
        setComparables({
          [lead.type]: lead.additionalData.comparable_props
        });

        window.parent.postMessage(
          {
            type: IVTEventsEnum.IVT_LEAD_CREATED,
            payload: {
              lead_type: lead.type,
              office: lead.office?.name
            }
          },
          "*"
        );

        actions.setSubmitting(false);
        next();
      } catch (err) {
        console.log(err);
      }
    },
    [
      appId,
      host,
      setLead,
      setComparables,
      setValuationReportMode,
      incompleteLeadUUID,
      facebookPixelId,
      utmParams
    ]
  );

  return (
    <Wizard>
      {nextPage => (
        <FormWizard<Partial<PropertyValuationFormDataType>>
          style={{
            width: "100%",
            height: "100%"
          }}
          onSubmit={handleFormSubmit(nextPage)}
          onFirstStepComplete={handleSubmitIncompleteLead}
        >
          {({ handleSubmit, values }) => (
            <Layout>
              <PropertyDetailsForm
                values={values}
                handleSubmit={handleSubmit}
              />
            </Layout>
          )}
          {({ handleSubmit, values }) => (
            <Layout>
              <ContactDetailsForm values={values} handleSubmit={handleSubmit} />
            </Layout>
          )}
        </FormWizard>
      )}
      {() => {
        if (!!lead) {
          return (
            <ValuationReport
              lead={lead}
              reportDownloadEnabled={reportDownloadEnabled}
            />
          );
        }

        return null;
      }}
    </Wizard>
  );
};
