import axios from "axios";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { TQuestionnaireOriginLead } from "../types/lead.type";
import { TFormResponseState } from "types/questionnaire.type";
import { FormCustomEndingPageConfig } from "@leadpro/forms";
const env = runtimeEnv();

export const questionnaireFormResponse = async (
  appId: string,
  data: Partial<any>,
  turnstileToken?: string
): Promise<FormCustomEndingPageConfig["params"]> => {
  const { data: responseData } = await axios.post(
    `${env.REACT_APP_NEW_API_BASE_URL}/questionnaires/responses`,
    data,
    {
      headers: {
        Authorization: appId,
        turnstileToken
      }
    }
  );
  return responseData;
};

export const questionnairePreviewFormResponse = async (
  appId: string,
  data: Partial<any>,
  turnstileToken?: string
): Promise<FormCustomEndingPageConfig["params"]> => {
  const { data: responseData } = await axios.post(
    `${env.REACT_APP_NEW_API_BASE_URL}/questionnaires/preview-responses`,
    data,
    {
      headers: {
        Authorization: appId,
        turnstileToken
      }
    }
  );
  return responseData;
};

export const getQuestionnaireResponseState = async (
  appId: string,
  leadPuplicUuid: string
): Promise<TFormResponseState> => {
  const { data } = await axios.get(
    `${env.REACT_APP_NEW_API_BASE_URL}/questionnaires/responses/${leadPuplicUuid}`,
    {
      headers: {
        Authorization: appId
      }
    }
  );

  return data;
};

export const getQuestionnaireOriginLead = async (
  appId: string,
  leadPublicUuid: string
): Promise<TQuestionnaireOriginLead> => {
  const { data } = await axios.get(
    `${env.REACT_APP_NEW_API_BASE_URL}/questionnaires/leads/${leadPublicUuid}`,
    {
      headers: {
        Authorization: appId
      }
    }
  );

  return data;
};
